<template>
    <div class="slide-menu-item-container " v-if="item.type==1">

      <div class="item-single item-complex" v-if="item.children&&item.isChild" @click="clickComplex()" >
        <div class="left-box">
          <a-icon :type="item.icons" />
        {{item.title}}
        </div>
        <div class="right-box">
          <a-icon type="caret-down" v-if="item.active" />
          <a-icon type="caret-right" v-else />
        </div>
      </div>

      <div :class="path==item.router?'item-single item-active':'item-single'" v-else  @click="toPage(item.router,item.title,'')" >
        <a-icon :type="item.icons"  v-if="item.icons&&item.icons!='normal'"  />
        {{item.title}}
      </div>

      <div class="item-list" v-if="item.children&&item.active">
        <div :class="path==menu.router?'item-single item-single-list item-active':'item-single item-single-list'" v-for="(menu,index) in item.children"  :key="index"  @click="toPage(menu.router,menu.title,item.title)" >
          {{menu.title}}
        </div>
      </div>
    </div>
</template>

<script>

import tool from '@/utils/tool'
export default {
  name: "Slide-menu-item",
  data() {
    return {
      // path:''
    };
  },
  props:{
    item:{
      type:Object
    }
  },
  computed: {
    path(){
      return this.$route.path
    }
  },
  mounted(){
    // console.log(this.$route.path)
    // this.path=this.$route.path
  },
  methods: {
    toPage(url,name,supName){
      let param={
        name,
        supName
      }
      tool.$emit('setName',param)
      this.$router.push(url)
    },
    clickComplex(){
      this.$emit('clickComplex')
    }
  }
};
</script>

<style lang="less" scoped>
.slide-menu-item-container {
  color: #FFFFFF;
  font-size: 18px;
  .item-single{
    height: 50px;
    padding-left: 45px;
    line-height: 50px;
    cursor: pointer;
    transition: all .3s;
  }
  .item-active{
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    background-color: #F6F7FA;
    color: #336FFF;
  }
  .item-complex{
    display: flex;
    justify-content: space-between;
    .right-box{
      margin-right: 30px;
      color: #666666;

    }
  }
  .item-list{
    .item-single-list{
      padding-left: 88px;
    }
  }



}
</style>
