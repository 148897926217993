<template>
    <div class="slide-menu-list-container">
        <menu-item
                v-for="(item, index) in list"
                :item="item"
                :key="index"
                @clickComplex="clickComplex(index)"
        />
    </div>
</template>

<script>
    import MenuItem from "./MenuItem.vue";
    import tool from '@/utils/tool'

    export default {
        name: "Slide-menu-list",
        components: {
            MenuItem,
        },
        data() {
            return {
                list: [],
            };
        },
        computed: {},
        activated() {
            // this.list=this.$store.state.menus
        },
        created() {

        },
        mounted() {
            this.list = this.$store.state.menus
            console.log('menus挂载');
            // this.getInfo();
            // this.getMenus()
            // tool.$on('changeMenu',(res)=>{
            //   this.getMenus();
            // })
        },
        methods: {
            getMenus() {
                if ((navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i)))
                    this.$store.state.isMobile = false;
                this.$api.post("v2/adminRules/menus", {is_mobile: this.$store.state.isMobile}).then((res) => {
                    console.log(res);
                    if (res.code == 200) {
                        this.list = res.data.map(item => {
                            item.isChild = false
                            for (let i = 0; i < item.children.length; i++) {
                                if (item.children[i].type == 1) {
                                    item.isChild = true
                                }
                            }
                            item.active = false;
                            return item
                        })
                        this.handleMenus(this.list)
                        // this.$router.push(this.list[0].router)
                    } else {
                        this.$message.error(res.msg)
                    }
                });
            },

            //递归处理页面
            handleMenus(child) {
                for (let i = 0; i < child.length; i++) {
                    if (child[i].type == 1) {
                        // this.$store.state.whitePath.push(child[i].router)
                    }
                    if (child[i].children.length > 0) {
                        this.handleMenus(child[i].children)
                    }
                }
            },

            getInfo() {
                this.$api.get("v1/user/info").then((res) => {
                    console.log(res);
                    if (res.code == 200) {
                        this.list = this.$router.options.routes[2].children;
                        if (res.data.is_admin != 1) {
                            this.$store.state.blackPath.push(this.list[1].children[1].path)
                            this.list[1].children.splice(1, 1);
                            if (res.data.children == 0) {
                                // this.$store.state.blackPath.push('/index/dashboard')
                                // this.list.splice(0,1)
                                this.$router.push('/')
                            }
                        }
                        console.log("list", this.list);
                    }
                });
            },
            clickComplex(index) {
                if (this.list[index].active) {
                    this.list[index].active = false;
                } else {
                    this.list = this.list.map((item) => {
                        item.active = false;
                        return item;
                    });
                    this.list[index].active = true;
                }
                console.log(this.list)
            },
        },
    };
</script>

<style lang="less" scoped>
    .slide-menu-list-container {
        margin-top: 40px;
    }
</style>
